import '../styles/globals.css'
import Head from 'next/head'
import {useRouter} from "next/router";
import Script from 'next/script'
import {IntlProvider} from "react-intl";

function MyApp({Component, pageProps}) {
  const router = useRouter()

  return (
    <IntlProvider
      locale={router.locale}
      defaultLocale={router.defaultLocale}
      messages={pageProps.intlMessages}
    >
      <Head>
        {router.locales.map((locale) => {
          return (
            <link
              key={locale}
              rel="alternate"
              hrefLang={locale}
              href={`https://www.flitspils.nl/${locale}${router.asPath}`}
            />
          )
        })}

        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
              <link rel="manifest" href="/site.webmanifest"/>
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
                  <meta name="msapplication-TileColor" content="#da532c"/>
                    <meta name="theme-color" content="#ffffff" />


      </Head>
      <Script defer data-domain="flitspils.nl" src="https://stats.flitspils.nl/js/statsflits.js"
              data-api="https://stats.flitspils.nl/flits"></Script>
      <Script id={"microsoft_clarity"} dangerouslySetInnerHTML={{
        __html: `
              (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i+"?ref=bwt";
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "ak7tvx3e2y");
          `
      }}>
      </Script>

      <Component {...pageProps} />
    </IntlProvider>
);
}

export default MyApp
